import React,{ useState, useEffect, useContext } from 'react';

import {useParams,useHistory} from "react-router-dom";
import {backend,fetchlatlng,app_home} from "./endpoints"

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import MapComponent from "./MapComponent"
import AltVsTime from "./AltVsTime"
import PressureVsTime from "./PressureVsTime"

import {DataRangeContext,GlobalContext,ChartHoverPointContext} from "./Contexts"
import {getFromCache,deleteItemFromCache} from "./cache"

const AnalysisPage = () => {

  let token = getFromCache("token")
  let history = useHistory()

  const { gctx, setGctx } = useContext(GlobalContext)

  const [dataRange, setDataRange] = useState([0,499]);  
  const [chartHoverIndex, setChartHoverIndex] = useState(0);  


  const [fullDatasetSize, setFullDatasetSize] = useState(0);  
  const [datasetSize, setDatasetSize] = useState("500");  
  const [isVertical, setIsVertical] = useState(true);
  const [selectedChart, setSelectedChart] = useState("1");  

  const containerClass = isVertical ? "flex-col" : "flex-row";
  const componentClass = isVertical ? "w-full" : "w-1/2";

  let { fileid } = useParams();

  const [plot_data, setPlotData] = useState(null);

  const handleDatasetChange = (event,newDataset) => {

    if (parseInt(newDataset) === 0){
      setDatasetSize(plot_data.full_data_size.toString());
      setDataRange([0,plot_data.full_data_size-1])  
    }
    else{
      setDatasetSize(newDataset);
      setDataRange([0,parseInt(newDataset)-1])  
    }
    
  };
  
  function logout(){
    deleteItemFromCache("token")
    setGctx({
        ...gctx,
        token:null
      })
    history.push(app_home)
  }

  // Fetch data from backend when component mounts
  useEffect(() => {
    fetch(
        backend+fetchlatlng+fileid+`/${datasetSize}`,
        {
          method: 'GET',
          headers: {
          'Authorization': token
        }
      })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 401) {
          console.error(response)
          logout()
        } else if (response.status === 404) {
          console.error(response)
          alert("Page not found - url might be incorrect")
        } else {
          console.error(response)
        }
      })
      // .then(res => res.json())
      .then(json => {
        setFullDatasetSize(json.full_data_size)
        setPlotData(json)
      })
      .catch((e)=>{
        console.log(e)
      });
  }, [fileid,datasetSize]);
  
  return (
    <ChartHoverPointContext.Provider value={{ chartHoverIndex, setChartHoverIndex }}>
    <DataRangeContext.Provider value={{ dataRange, setDataRange }}>
    <div className="px-4 flex flex-col">
      <h1 className="ml-8 mt-8 ml-4 text-4xl font-bold mb-4">Post-Flight Analysis: {plot_data ? plot_data.display_filename : null}</h1> 
      <div className="flex flex-row justify-end pr-6 items-center">
        <div className="flex flex-row mr-6 items-center">
          <div className="text-base font-bold text-gray-500 mr-4">
            Data Sample Size: 
          </div>
          <ToggleButtonGroup
            color="primary"
            value={datasetSize}
            exclusive
            onChange={handleDatasetChange}
            aria-label="Dataset Size"
            size="small"
          >
            <ToggleButton value="500">500</ToggleButton>
            <ToggleButton value="1000">1000</ToggleButton>
            <ToggleButton value="2000">2000</ToggleButton>
            <ToggleButton value={fullDatasetSize.toString()}>Full</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="flex flex-row mr-6 items-center">
          <ToggleButtonGroup
            color="primary"
            value={selectedChart}
            exclusive
            onChange={(e)=>setSelectedChart(e.target.value)}
            aria-label="Selected Chart"
            size="small"
          >
            <ToggleButton value="1">Alt/Speed Chart</ToggleButton>
            {/*<ToggleButton value="2">Fuel/Oil Chart</ToggleButton>*/}
          </ToggleButtonGroup>
        </div>
        <div>
        <button 
          className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" 
          onClick={() => setIsVertical(!isVertical)}>
          {isVertical ? "Switch to Horizontal Layout" : "Switch to Vertical Layout"}
        </button>
        </div>
      </div>
      <div className={`px-4 py-4 flex ${containerClass} items-start justify-between`}>
        <div className={`mb-8 ${componentClass}`}>
          <FlightPathComponent plot_data={plot_data} datasetSize={datasetSize} />
        </div>
        <div className={`mb-8 ${componentClass}`}>
          {selectedChart === "1" ?
            <AltVsTimeContainer plot_data={plot_data} />
            :
            null
          }
          {selectedChart === "2" ?
            <PressureVsTimeContainer plot_data={plot_data} />
            :
            null
          }
        </div>
      </div>
    </div>
    </DataRangeContext.Provider>
    </ChartHoverPointContext.Provider>
  );
};

// Replace with your own Altitude and Speed components

const FlightPathComponent = (props) => {
  return (
    <div className="m-2 bg-white shadow rounded-lg p-4">
      <h3 className="text-lg font-bold mb-2">Flight Path Map</h3>
      
      <div className="w-full">
        { props.plot_data 
          ? 
          <MapComponent 
            latitudes={props.plot_data.latitude} 
            longitudes={props.plot_data.longitude} 
            groundSpeed={props.plot_data.ground_speed}
            indicatedAirspeed={props.plot_data.indicated_airspeed}
            altitudes={props.plot_data.baro_altitude} 
            timestamps={props.plot_data.timestamp}
            datasetSize={props.datasetSize} 
          /> 
          : 
          <div>Loading...</div>
        }
      </div>

      <p className="text-xs text-gray-700 mt-4">
        The flight path map displays the GPS pings of the aircraft's 
        journey by plotting the latitude and longitude coordinates on a 
        satellite map.
        
        The markers are color-graded to show the progression of the flight, 
        gradually changing color as the journey progresses. 
        
        Each marker is labeled with an index number in the order of the flight journey, 
        visible on hover. 
        The map is interactive and can be zoomed in or panned around using mouse controls.
      </p>
    </div>
  );
};

const AltVsTimeContainer = (props) => {

  return (
    <div className="m-2 bg-white shadow rounded-lg p-4 w-full">
      <h3 className="text-lg font-bold mb-2">Altitude Change Over Time During Flight</h3>
      <div className="w-full">
        {
          props.plot_data ? 
          <AltVsTime 
            groundSpeed={props.plot_data.ground_speed}
            indicatedAirspeed={props.plot_data.indicated_airspeed}
            altitudes={props.plot_data.baro_altitude} 
            timestamps={props.plot_data.timestamp} />
          : <div>Loading...</div>
        }
      </div>

      <p className="text-gray-700 mt-4 text-xs">
        This interactive chart displays the altitude  and speed change over time during a flight, 
        allowing pilots to analyze their flight data. 
        The chart allowing pilots and instructors to closely examine specific time periods using the 
        slider, and also get detailed statistics like min, max and average on the altitude and speed data
        in the selected time range.

        Additionally, hovering over the chart displays the altitude and speed data at the 
        corresponding point in time.
      </p>

    </div>
  );
};



const PressureVsTimeContainer = (props) => {

  return (
    <div className="m-2 bg-white shadow rounded-lg p-4 w-full">
      <h3 className="text-lg font-bold mb-2">Fuel & Oil Pressure Change Over Time During Flight</h3>
      <div className="w-full">
        {
          props.plot_data ? 
            <PressureVsTime
            fuelPressure={props.plot_data.fuel_pressure}
            oilPressure={props.plot_data.oil_pressure}
            timestamps={props.plot_data.timestamp} />
          : <div>Loading...</div>
        }
      </div>

      <p className="text-gray-700 mt-4 text-xs">
        This interactive chart displays the fuel pressure and oil pressure change over time during a flight, 
        allowing pilots to analyze their flight data. 
        The chart allowing pilots and instructors to closely examine specific time periods using the 
        slider, and also get detailed statistics like min, max and average on the fuel pressure and oil pressure data
        in the selected time range.

        Additionally, hovering over the chart displays the altitude and speed data at the 
        corresponding point in time.
      </p>

    </div>
  );
};

export default AnalysisPage;
