import * as React from 'react';
import { useContext } from 'react';

import {ChartHoverPointContext} from "./Contexts"

function getColorCode(num,max) {
  // Convert the input number to a value between 0 and 1
  var value = num / max;

  // Use the value to calculate the RGB values for the color
  var red = Math.round(255 * (1 - value));
  var green = Math.round(255 * value);
  var blue  = Math.round(128 * Math.sin(value * Math.PI) + 128);

  blue = Math.min(Math.max(blue, 0), 255);

  // Convert the RGB values to a hex color code
  var colorCode = "#" + padZero(red.toString(16)) + padZero(green.toString(16)) + padZero(blue.toString(16));

  // Return the color code
  return colorCode;
}

function padZero(hexString) {
  return hexString.length == 1 ? "0" + hexString : hexString;
}




function Pin(props) {

  const { chartHoverIndex } = useContext(ChartHoverPointContext)


  let pinStyle1 = {
    cursor: 'pointer',
    fill:  '#FFFFFF',
    stroke: 'none'
  };

  let pinStyle2 = {
    cursor: 'pointer',
    fill:  getColorCode(props.index,props.max),
    stroke: 'none',

  };


  return (
    chartHoverIndex === props.index ?
    <svg height={100} viewBox="0 0 100 100" style={pinStyle1}>
      <circle cx="50" cy="50" r={"10"} stroke="black" strokeWidth="0" fill='#FFFFFF' />
      <text x="44" y="55" fill="#000">✈️</text>
    </svg>
    :
    <svg height={100} viewBox="0 0 100 100" style={pinStyle2}>
      <circle cx="50" cy="50" r={"5"} stroke="black" strokeWidth="0" fill={getColorCode(props.index,props.max)} />
    </svg>
  );
}

export default React.memo(Pin);