import React, { useState,useEffect,useRef,useContext } from 'react';
import ReactMapGL, { Marker,ScaleControl,NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import {DataRangeContext} from "./Contexts"


import Pin from './Pin';

const getBounds = (latitudes, longitudes) => {
    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);
    const latitude = (minLat + maxLat) / 2;
    const longitude = (minLng + maxLng) / 2;
    return { latitude, longitude };
  };


const MapComponent = ({ 
  latitudes, longitudes,
  groundSpeed,indicatedAirspeed, 
  altitudes, timestamps, datasetSize}) => {


  const { dataRange, setDataRange } = useContext(DataRangeContext)

  // const [latitudes, setLatitudes] = useState([]);
  // const [longitudes, setLongitudes] = useState([]);

  const mapRef = useRef(null);

  const bounds = getBounds(latitudes, longitudes);

  const [viewport, setViewport] = useState({
    latitude: bounds.latitude,
    longitude: bounds.longitude,
    zoom:8
  });

  const [settings, setSettings] = useState({
    scrollZoom: true,
    boxZoom: true,
    dragRotate: true,
    dragPan: true,
    keyboard: true,
    doubleClickZoom: true,
    touchZoomRotate: true,
    touchPitch: true,
    minZoom: 0,
    maxZoom: 20,
    minPitch: 0,
    maxPitch: 85
  });

  // useEffect(() => {
  //   setLatitudes(latitudes_prop)
  //   setLongitudes(longitudes_prop)
  // }, [latitudes_prop,longitudes_prop]);


  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.getMap().resize(); // update the map's width
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const markerIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#FF0000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12 2C8.13 2 5 5.13 5 9c0 3.86 2.13 7.37 5.5 9.19L12 22l1.5-.81C16.87 16.37 19 12.86 19 9c0-3.87-3.13-7-7-7z" />
      </svg>
    );
  };

  return (
    <div style={{width:"100%", height:"500px"}}>
      <ReactMapGL
        ref={mapRef}
        initialViewState={viewport}
        {...settings}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        // onViewportChange={(viewport) => setViewport(viewport)}
      >
        { latitudes.length ? latitudes.slice(dataRange[0],dataRange[1]+1).map((lat, i) => (
          <Marker key={i} latitude={lat} longitude={longitudes.slice(dataRange[0],dataRange[1]+1)[i]} offsetTop={-20} offsetLeft={-10}>
            <Pin 
              index={i} 
              alti={altitudes[i]} 
              gndspd={groundSpeed[i]} 
              ias={indicatedAirspeed[i]} 
              time={timestamps[i]}
              max={parseInt(datasetSize)+10}
            />
          </Marker>
        )) : null}
        <ScaleControl />
        <NavigationControl />
      </ReactMapGL>
    </div>
  );
};

export default MapComponent;
