import React,{ useState,useEffect,useContext } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend  
} from 'chart.js';


import { Line } from 'react-chartjs-2';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import {DataRangeContext,ChartHoverPointContext} from "./Contexts"


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function PressureVsTime({ fuelPressure, oilPressure, timestamps}) {
  
  const { dataRange, setDataRange } = useContext(DataRangeContext)
  const { chartHoverIndex, setChartHoverIndex } = useContext(ChartHoverPointContext)

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Fuel Pressure (PSI) vs Time and Oil Pressure (PSI) vs Time',
      },
    },
    scales: {
      x:{
        ticks: {
            display: false,
        },
        grid: {
            display: false,
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        setChartHoverIndex(chartElement[0].index)
      }
    }
  };

  const [fuelDataset,setFuelDataset] = useState([])
  
  const [stats,setStats] = useState({
    fuel:{
      min:0.0,
      max:0.0,
      avg:0.0
    },
    oil:{
      min:0.0,
      max:0.0,
      avg:0.0
    }
  })

  const [oilDataset,setOilDataset] = useState([])

  const [sliderValue, setSliderValue] = React.useState([0,timestamps.length-1]);

  function calculateStats(values){

    const sum = values.reduce((acc, curr) => acc + curr, 0);
    const average = sum / values.length;

    const minimum = Math.min(...values);
    const maximum = Math.max(...values);
    return [minimum,maximum,average]
  }

  function calculateNewStats(fuel,oil,start,end){
    let [amin,amax,aavg] = calculateStats(fuel.slice(start,end+1))
    let [smin,smax,savg] = calculateStats(oil.slice(start,end+1))



    setStats({
      fuel:{
        min:smin,
        max:smax,
        avg:savg
      },
      oil:{
        min:amin,
        max:amax,
        avg:aavg
      }
    })
  }


  function handleSliderChange(event, newValue) {
    setSliderValue(newValue);
    setDataRange(newValue)

    let fuel_tmp = []
    for(let i=newValue[0];i<newValue[1]+1;i++){
      fuel_tmp.push({
        x:timestamps[i],
        y:fuelPressure[i]
      })
    }
    setFuelDataset(fuel_tmp)


    let oil_tmp = []
    for(let i=newValue[0];i<newValue[1]+1;i++){
      oil_tmp.push({
        x:timestamps[i],
        y:oilPressure[i] 
      })
    }
    setOilDataset(oil_tmp)
    calculateNewStats(fuelPressure,oilPressure,newValue[0],newValue[1])
  };

  useEffect(()=>{
    
    let fuel_tmp = []
    for(let i=0;i<fuelPressure.length;i++){
      fuel_tmp.push({
        x:timestamps[i],
        y:fuelPressure[i]
      })
    }
    setFuelDataset(fuel_tmp)

    
    let oil_tmp = []
    for(let i=0;i<oilPressure.length;i++){
      oil_tmp.push({
        x:timestamps[i],
        y:oilPressure[i] 
      })
    }
    setOilDataset(oil_tmp)

    calculateNewStats(fuelPressure,oilPressure,0,oilPressure.length)
    
    
    setSliderValue([0,timestamps.length-1])
    setDataRange([0,timestamps.length-1])

  },[fuelPressure, oilPressure, timestamps])
  
  function valuetext(value) {
    return `${timestamps[value]}`;
  }

  const data = {
    datasets: [
      {
        label: 'Fuel Pressure (PSI)',
        data: fuelDataset,
        borderColor: 'rgb(3, 101, 192)',
        backgroundColor: 'rgba(3, 101, 192, 0.5)',
        yAxisID: 'y'
      },
      {
        label: 'Oil Pressure (PSI)',
        data: oilDataset,
        borderColor: 'rgb(0, 158, 73)',
        backgroundColor: 'rgba(0, 158, 73, 0.5)',
        yAxisID: 'y1'
      }
    ],
  };

  return (<>
      <Line options={options} data={data} />
      <div className="mt-8">
        <Box sx={{ width: "100%" }}>
          <Slider
            value={sliderValue}
            min={0}
            max={timestamps.length ? timestamps.length - 1 : 100}
            onChange={handleSliderChange}
            valueLabelDisplay="off"
            getAriaValueText={valuetext}
          />
        </Box>
        <div className="flex flex-col py-10 px-5">

          <div className="my-2 flex flex-col items-center py-3 border">
            <div className="flex flex-row">
              <div className="mr-2 font-bold">Start time:</div>
              <div className="font-mono">{timestamps.length ? timestamps[sliderValue[0]] : 'loading...'}</div>
            </div>

            <div className="flex flex-row">
              <div className="mr-2 font-bold">End time:</div>
              <div className="font-mono">{timestamps.length ? timestamps[sliderValue[1]] : 'loading...'}</div>
            </div>
          </div>

          <div className="my-2 flex flex-row items-center justify-around">
            <div className="my-2 p-3 border">
              <div className="flex flex-row">
                <div className="mr-2 font-bold">Min Fuel Pressure:</div>
                <div className="font-mono">{Number((stats.fuel.min).toFixed(2))} PSI</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Max Fuel Pressure:</div>
                <div className="font-mono">{Number((stats.fuel.max).toFixed(2))} PSI</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Average Fuel Pressure:</div>
                <div className="font-mono">{Number((stats.fuel.avg).toFixed(2))} PSI</div>
              </div>
            </div>

            
            <div className="my-2 p-3 border">
              <div className="flex flex-row">
                <div className="mr-2 font-bold">Min Oil Pressure:</div>
                <div className="font-mono">{Number((stats.oil.min).toFixed(2))} PSI</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Max Oil Pressure:</div>
                <div className="font-mono">{Number((stats.oil.max).toFixed(2))} PSI</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Average Oil Pressure:</div>
                <div className="font-mono">{Number((stats.oil.avg).toFixed(2))} PSI</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>);

}


export default PressureVsTime


