import React, { useState,useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import {login,app_upload} from "./endpoints"
import {setItemInCache} from "./cache"
import {GlobalContext} from "./Contexts"

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { gctx, setGctx } = useContext(GlobalContext)

  
  const history = useHistory(); 

  const forgotPassword = async () => {
    toast.blank("You can contact airplotter.com@gmail.com for resetting your password.");
  }

  const handleLogin = async () => {
    if (!email || !password) {
      toast.error('Please enter both email and password');
      return;
    }

    try {
      const response = await axios.post(login, 
        { 
            email:email, 
            password:password 
        }
      );
      toast.success("Logged in");
      setItemInCache("token",response.data.token)
      
      setGctx({
        ...gctx,
        token:response.data.token
      })

      history.push(app_upload);
      
    } catch (error) {
      toast.error("Oops! An error occurred.");
    }
  };

  return (
    <div className="flex-grow w-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Login to Airplotter
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <div onClick={forgotPassword} className="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </div>
            </div>
          </div>

          <div>
            <button
              onClick={handleLogin}
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <i className="fas fa-sign-in-alt text-white mr-2"></i>
              </span>
              Sign in
            </button>
            {
              // <p className="mt-2 text-center text-sm text-gray-600">
              //   Or{' '}
              //   <Link to={app_signup} className="font-medium text-indigo-600 hover:text-indigo-500">
              //     create a new account
              //   </Link>
              // </p>
            }
            <Toaster />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;

