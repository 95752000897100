import React,{ useState,useEffect,useContext } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend  
} from 'chart.js';


import { Line } from 'react-chartjs-2';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import {DataRangeContext,ChartHoverPointContext} from "./Contexts"


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function AltVsTime({ altitudes, timestamps, indicatedAirspeed, groundSpeed }) {
  
  const { dataRange, setDataRange } = useContext(DataRangeContext)
  const { chartHoverIndex, setChartHoverIndex } = useContext(ChartHoverPointContext)

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Altitude (ft) vs Time and Speed (kts) vs Time',
      },
    },
    scales: {
      x:{
        ticks: {
            display: false,
        },
        grid: {
            display: false,
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
      }
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        setChartHoverIndex(chartElement[0].index)
      }
    }
  };

  const [altDataset,setAltDataset] = useState([])
  const [selectedSpeed,setSelectedSpeed] = useState("ias") // ias or gnd
  
  const [stats,setStats] = useState({
    airspeed:{
      min:0.0,
      max:0.0,
      avg:0.0
    },
    altitude:{
      min:0.0,
      max:0.0,
      avg:0.0
    }
  })

  const [speedDataset,setSpeedDataset] = useState([])

  const [sliderValue, setSliderValue] = React.useState([0,timestamps.length-1]);

  function calculateStats(values){

    const sum = values.reduce((acc, curr) => acc + curr, 0);
    const average = sum / values.length;

    const minimum = Math.min(...values);
    const maximum = Math.max(...values);
    return [minimum,maximum,average]
  }

  function calculateNewStats(altitudes,airspeed,start,end){
    let [amin,amax,aavg] = calculateStats(altitudes.slice(start,end+1))
    let [smin,smax,savg] = calculateStats(airspeed.slice(start,end+1))



    setStats({
      airspeed:{
        min:smin,
        max:smax,
        avg:savg
      },
      altitude:{
        min:amin,
        max:amax,
        avg:aavg
      }
    })
  }


  function handleSliderChange(event, newValue) {
    setSliderValue(newValue);
    setDataRange(newValue)

    let alt = []
    for(let i=newValue[0];i<newValue[1]+1;i++){
      alt.push({
        x:timestamps[i],
        y:altitudes[i]
      })
    }
    setAltDataset(alt)

    let airspeed = (selectedSpeed === 'ias' ? indicatedAirspeed : groundSpeed)

    let speed = []
    for(let i=newValue[0];i<newValue[1]+1;i++){
      speed.push({
        x:timestamps[i],
        y:airspeed[i] 
      })
    }
    setSpeedDataset(speed)
    calculateNewStats(altitudes,airspeed,newValue[0],newValue[1])
  };

  useEffect(()=>{
    
    let alt = []
    for(let i=0;i<altitudes.length;i++){
      alt.push({
        x:timestamps[i],
        y:altitudes[i]
      })
    }
    setAltDataset(alt)

    if (selectedSpeed === 'ias'){
      let speed = []
      for(let i=0;i<indicatedAirspeed.length;i++){
        speed.push({
          x:timestamps[i],
          y:indicatedAirspeed[i]
        })
      }
      setSpeedDataset(speed)
      calculateNewStats(altitudes,indicatedAirspeed,0,indicatedAirspeed.length)
    }
    else if (selectedSpeed === 'gnd'){
      let speed = []
      for(let i=0;i<groundSpeed.length;i++){
        speed.push({
          x:timestamps[i],
          y:groundSpeed[i]
        })
      }
      setSpeedDataset(speed)
      calculateNewStats(altitudes,groundSpeed,0,groundSpeed.length)
    }
    
    setSliderValue([0,timestamps.length-1])
    setDataRange([0,timestamps.length-1])

  },[altitudes, timestamps, indicatedAirspeed, groundSpeed, selectedSpeed])
  
  function valuetext(value) {
    return `${timestamps[value]}`;
  }

  const data = {
    datasets: [
      {
        label: 'Altitude (ft)',
        data: altDataset,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y'
      },
      {
        label: 'Speed (kts)',
        data: speedDataset,
        borderColor: 'rgb(132, 99, 255)',
        backgroundColor: 'rgba(132, 99, 255, 0.5)',
        yAxisID: 'y1'
      }
    ],
  };

  return (<>
      <div className="flex flex-row justify-end my-5">
        <ToggleButtonGroup
          color="primary"
          value={selectedSpeed}
          exclusive
          onChange={(e,s)=>setSelectedSpeed(s)}
          aria-label="Select Speed"
          size="small"
        >
          <ToggleButton value="gnd">GPS Ground Speed</ToggleButton>
          <ToggleButton value="ias">Indicated Airspeed</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <Line options={options} data={data} />
      <div className="mt-8">
        <Box sx={{ width: "100%" }}>
          <Slider
            value={sliderValue}
            min={0}
            max={timestamps.length ? timestamps.length - 1 : 100}
            onChange={handleSliderChange}
            valueLabelDisplay="off"
            getAriaValueText={valuetext}
          />
        </Box>
        <div className="flex flex-col py-10 px-5">

          <div className="my-2 flex flex-col items-center py-3 border">
            <div className="flex flex-row">
              <div className="mr-2 font-bold">Start time:</div>
              <div className="font-mono">{timestamps.length ? timestamps[sliderValue[0]] : 'loading...'}</div>
            </div>

            <div className="flex flex-row">
              <div className="mr-2 font-bold">End time:</div>
              <div className="font-mono">{timestamps.length ? timestamps[sliderValue[1]] : 'loading...'}</div>
            </div>
          </div>

          <div className="my-2 flex flex-row items-center justify-around">
            <div className="my-2 p-3 border">
              <div className="flex flex-row">
                <div className="mr-2 font-bold">Min Altitude:</div>
                <div className="font-mono">{Number((stats.altitude.min).toFixed(2))} ft.</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Max Altitude:</div>
                <div className="font-mono">{Number((stats.altitude.max).toFixed(2))} ft.</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Average Altitude:</div>
                <div className="font-mono">{Number((stats.altitude.avg).toFixed(2))} ft.</div>
              </div>
            </div>

            
            <div className="my-2 p-3 border">
              <div className="flex flex-row">
                <div className="mr-2 font-bold">Min AirSpeed:</div>
                <div className="font-mono">{Number((stats.airspeed.min).toFixed(2))} kts</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Max AirSpeed:</div>
                <div className="font-mono">{Number((stats.airspeed.max).toFixed(2))} kts</div>
              </div>

              <div className="flex flex-row">
                <div className="mr-2 font-bold">Average AirSpeed:</div>
                <div className="font-mono">{Number((stats.airspeed.avg).toFixed(2))} kts</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>);

}


export default AltVsTime


