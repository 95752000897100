import React from "react";

const ContactUs = () => {
  return (
    <div className="flex flex-col items-center justify-center mb-16">
      <h2 className="text-4xl font-bold text-gray-900 my-8">Contact Us</h2>
      <h3 className="text-2xl font-bold text-gray-900 mb-4">For support or any other queries</h3>
      <ul className="list-disc list-inside">
        <li className="text-gray-700 mb-2">Phone Number: +91 7904791818</li>
        <li className="text-gray-700 mb-2">Email: airplotter.com@gmail.com</li>
      </ul>
    </div>
  );
};

export default ContactUs;
