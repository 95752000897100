import React, { useState } from 'react';
import axios from 'axios';

import { useHistory } from 'react-router-dom';
import {backend,fileupload} from "./endpoints"

import {getFromCache} from "./cache"

function FileUpload() {
  
  let token = getFromCache("token")

  let history = useHistory()

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    try {
      let response = await axios.post(backend+fileupload, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      });

      // console.log(response)
      history.push(`/analysis/${response.data.file_id}`)
      // alert('File uploaded successfully!');
    } catch (error) {
      console.error(error);
      alert('Failed to upload file.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        Upload your flight data
      </h1>
      <div className="mb-8 text-gray-800 text-center">
        <p className="mb-2">To get started, upload a CSV file of your flight data.</p>
        <p>The data will be analyzed in the Air Plotter flight debriefing software.</p>
      </div>
      <form onSubmit={handleFormSubmit} className="w-full max-w-md">
        <div className="flex items-center justify-center">
          <label
            htmlFor="file-upload"
            className="px-4 py-2 bg-gray-800 text-gray-300 rounded cursor-pointer hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {file ? file.name : "Select a file"}
          </label>
          <input
            id="file-upload"
            type="file"
            className="sr-only"
            onChange={handleFileChange}
          />
          <button
            type="submit"
            className="ml-4 py-2 px-4 bg-indigo-600 text-white rounded hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            disabled={!file}
          >
            Upload
          </button>
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
