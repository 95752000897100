// Import React and the PricingPlan component
import React from "react";
import PricingPlan from "./PricingPlan";

const Pricing = () => {
  // Define an array of pricing plan objects
  const pricingPlans = [
    {
      name: "Pilot Plan",
      price: "$99",
      features: [
        "Single account for pilots",
        "Custom flight data plots",
        "Secure flight data vault",
        "Multiple map layer options",
        "Support for all aircraft types",
        "Advanced data exporting options",
        "Collaborative file sharing",
        "24x7 Customer support"
      ]
    },
    {
      name: "Flight School Plan",
      price: "$799",
      features: [
        "All Student Plan features",
        "Unlimited student accounts",
        "Student management system",
        "Advanced flight safety analytics",
        "Role based access controls",
        "Custom branding options",
        "Dedicated 24x7 customer support"
      ]
    }
  ];

  return (
    <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Pricing Plans
        </h2>
        <p className="mt-4 text-lg text-gray-500">
          Choose the plan that works for you.
        </p>
        <div className="mt-16 space-y-4 flex flex-row flex-wrap justify-around items-center">
          {/* Map over the pricingPlans array to render a PricingPlan component for each plan */}
          {pricingPlans.map(plan => (
            <PricingPlan
              key={plan.name}
              name={plan.name}
              price={plan.price}
              features={plan.features}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
