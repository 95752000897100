export function getFromCache(key){
	return localStorage.getItem(key);
}

export function setItemInCache(key,value){
	return localStorage.setItem(key,value);
}

export function deleteItemFromCache(key){
	localStorage.removeItem(key);
	return true
}