import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { useState } from "react";
import FileUpload from "./FileUpload"
import Header from "./Header"
import LandingBody from "./LandingBody"
import Pricing from "./Pricing"
import ContactUs from "./ContactUs"
import AnalysisPage from "./AnalysisPage"
import Login from "./Login"
import Files from "./Files"

import {GlobalContext} from "./Contexts"
import {getFromCache,setItemInCache} from "./cache"
import * as endpoints from "./endpoints"

import './App.css';

function App() {

  const [gctx, setGctx] = useState({
    token: getFromCache("token")
  });  

  return (
    <GlobalContext.Provider value={{ gctx, setGctx }}>
    <div className="flex flex-col h-screen">
      <Router forceRefresh={true}>
        <Header />
        <Switch>
          <Route path={endpoints.app_pricing} component={Pricing} />
          <Route path={endpoints.app_features} component={LandingBody} />
          <Route path={endpoints.app_contact} component={ContactUs} />
          <Route path={endpoints.app_analysis} component={AnalysisPage} />
          <Route path={endpoints.app_upload} component={FileUpload} />
          <Route path={endpoints.app_login} component={Login} />
          {/*<Route path={endpoints.app_files} component={Files} />*/}
          
          <Route path={endpoints.app_home} component={LandingBody} />
          
        </Switch>
      </Router>
    </div>
    </GlobalContext.Provider>
  );
}

export default App;
