import React, { useState,useContext,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import * as endpoints from "./endpoints"
import { Link,useHistory } from 'react-router-dom';
import {getFromCache,deleteItemFromCache} from "./cache"
import {GlobalContext} from "./Contexts"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [token, setToken] = useState("");
  
  let history = useHistory()

  const { gctx, setGctx } = useContext(GlobalContext)

  useEffect(()=>{
    setToken(gctx.token)
  },[gctx.token])

  function logout(){
    deleteItemFromCache("token")
    setGctx({
        ...gctx,
        token:null
      })
    history.push(endpoints.app_home)
  }

  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
        <div className="flex items-center">
          <Link className="font-bold text-xl text-gray-800" to={endpoints.app_home}>
            Air Plotter
          </Link>
        </div>

        <div className="hidden md:block">
        {
          token ? 

          <ul className="ml-4 md:flex md:items-center">
            <li>
              <Link
                to={endpoints.app_upload}
                className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
              >
                New Upload
              </Link>
            </li>
            {
              // <li>
              //   <Link
              //     className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
              //     to={endpoints.app_files}
              //   >
              //     Browse Files
              //   </Link>
              // </li>
            }
            <li>
              <Link
                className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
                to={endpoints.app_contact}
              >
                Support
              </Link>
            </li>
            <li className="my-2">
              <button
                onClick={logout}
                className="block border-l text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
              >
                Logout
              </button>
            </li>
          </ul>
              :
          <ul className="ml-4 md:flex md:items-center">
            <li>
              <Link
                className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
                to={endpoints.app_features}
              >
                Features
              </Link>
            </li>
            <li>
              <Link
                className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
                to={endpoints.app_pricing}
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                className="block text-gray-800 hover:text-blue-500 font-semibold py-1 md:p-2 lg:px-4 xl:px-5"
                to={endpoints.app_contact}
              >
                Contact
              </Link>
            </li>
            
            <li>
              <Link
                to={endpoints.app_login}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
              >
                Login
              </Link>
            </li>
          </ul>
        }
        </div>

        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="block text-gray-800 hover:text-blue-500 focus:text-blue-500 focus:outline-none"
          >
            <FontAwesomeIcon icon={faBars} className="w-6 h-6 fill-current" />
          </button>
        </div>

        {isOpen && (
          <div className="md:hidden">
            {
            token ? 
            <ul className="mt-2">
              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_upload}
                  onClick={() => setIsOpen(false)}
                >
                  New Upload
                </Link>
              </li>

              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_files}
                  onClick={() => setIsOpen(false)}
                >
                  Browse Files
                </Link>
              </li>

              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_contact}
                  onClick={() => setIsOpen(false)}
                >
                  Support
                </Link>
              </li>
              <hr/>
              <li>
                <button
                  onClick={()=>{ setIsOpen(false); logout()} }
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                >
                  Logout
                </button>
              </li>
            </ul>
            :
            <ul className="mt-2">
              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_features}
                  onClick={() => setIsOpen(false)}
                >
                  Features
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_pricing}
                  onClick={() => setIsOpen(false)}
                >
                  Pricing
                </Link>
              </li>
              <li>
                <Link
                  className="block text-gray-800 hover:text-blue-500 font-semibold py-1"
                  to={endpoints.app_contact}
                  onClick={() => setIsOpen(false)}
                >
                  Contact
                </Link>
              </li>
              <li className="my-2">
                <Link
                  to={endpoints.app_login}
                  onClick={() => setIsOpen(false)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
                >
                  Login
                </Link>
              </li>
            </ul>
          }
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
