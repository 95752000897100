export const backend = "" // "http://localhost:8080"
export const fileupload = "/airplotter/api/upload"
export const fetchfile = "/airplotter/api/file/"
export const fetchlatlng = "/airplotter/api/get/plots/"
export const login = "/airplotter/api/login"
export const listfiles = "/airplotter/api/list/files"

export const app_pricing = "/pricing"
export const app_features = "/features"
export const app_contact = "/contact"
export const app_analysis = "/analysis/:fileid"
export const app_analysis_prefix = "/analysis/"
export const app_download_file_prefix = "/airplotter/api/file/download?filename="
export const app_upload = "/upload"
export const app_login = "/login"
export const app_files = "/files"
export const app_home = "/"