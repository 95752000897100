import React from "react";

const PricingPlan = ({ name, price, features }) => {
  return (
    <div className="shadow-lg rounded-lg">
      <div className="px-6 py-8 bg-white rounded-t-lg">
        <h3 className="text-2xl font-bold text-gray-900">{name}</h3>
        <p className="mt-4 text-xl font-bold text-gray-900">{price}/month</p>
      </div>
      <div className="px-6 py-4 bg-gray-100 rounded-b-lg">
        <ul className="text-gray-600">
          {/* Map over the features array to render a list item for each feature */}
          {features.map(feature => (
            <li key={feature} className="flex items-start mt-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M17.707,5.293c0.391,0.391 0.391,1.023 0,1.414l-8,8c-0.391,0.391 -1.023,0.391 -1.414,0l-4,-4c-0.391,-0.391 -0.391,-1.023 0,-1.414s1.023,-0.391 1.414,0l3.293,3.293l7.293,-7.293c0.391,-0.391 1.023,-0.391 1.414,0Z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <div className="mt-8 flex justify-center">
          <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
            Choose Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
