import React from "react";

function LandingBody() {
  return (
  <div className="flex flex-col">
    <section className="container self-center px-2 py-16">
      <h2 className="text-4xl font-bold mb-8 text-center">Features</h2>
      <div className="flex flex-wrap justify-between items-center">
        <div className="w-full md:w-1/2 lg:w-96 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Flight Path Replay</h3>
            <p>
              Air Plotter allows pilots to replay their entire flight path with
              ease, giving them a complete understanding of their journey.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-96 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Digital Satellite Map</h3>
            <p>
              With Air Plotter, pilots can view their entire flight journey on a
              digital satellite map, making it easier to understand their
              location and surroundings.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-96 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Flight Sensor Data Graphs</h3>
            <p>
              Air Plotter plots altitude vs time and other graphs necessary for
              a complete understanding of flight characteristics.
            </p>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-96 mb-8">
          <div className="bg-white shadow-lg rounded-lg p-6">
            <h3 className="text-xl font-bold mb-2">Flight Alerts</h3>
            <p>
              Air Plotter allows pilots to set alerts for limits and ranges on
              all flight sensor data, ensuring that they are always aware of any
              issues or concerns.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  );
}

export default LandingBody;
